<template>
  <section class="account">
    <Sidebar>
      <template v-slot:header>
        Mein Account "{{customerId}}"
      </template>
      <template v-slot:body>
        <ul class="account__links">
<!--          <li
              class="account__link"
              id="account-link"
              @click="scrollTo('account')"
          >
            Rechnungsdaten
          </li>-->
          <li
              class="account__link"
              id="password-link"
              @click="scrollTo('password')"
          >
            Passwort ändern
          </li>
<!--          <li
              class="account__link"
              id="invoices-link"
              @click="scrollTo('invoices')"
          >
            Rechnungen
          </li>
          <li
              class="account__link"
              id="delete-link"
              @click="scrollTo('delete')"
          >
            Account löschen
          </li>-->
        </ul>
      </template>
    </Sidebar>
    <div class="account__main" ref="viewport" id="viewport">
      <div class="account__content" >
        <h2 class="account__headline">
          Willkommen in Ihrem Account.
        </h2>
<!--        <form class="form account__form" ref="account" id="account" data-invoice-form>
          <h3 class="account__sub-headline">
            Rechnungsdaten
          </h3>
          <div class="form__row">
            <div class="form__element">
              <label class="label form__label" for="email">E-Mail-Adresse*</label>
              <input class="input" id="email" name="email" type="email" data-form-input/>
            </div>
          </div>
          <div class="form__row">
            <div class="form__element">
              <label class="label form__label" for="account-name">Accountname* (z.B. Firmenname, oder Ihr Name)</label>
              <input class="input" id="account-name" name="account-name" type="text" data-form-input/>
            </div>
          </div>
          <div class="form__row">
            <label class="form__checkbox-container">Rechnung unverschlüsselt per Email versenden.
              <input type="checkbox">
              <span class="form__checkbox"></span>
            </label>
          </div>
          <h4>
            <strong>Rechnungsadresse:</strong>
          </h4>
          <div class="form__row">
            <div class="form__element">
              <label class="label form__label" for="name">Vorname*</label>
              <input class="input" id="name" name="name" type="text" data-form-input/>
            </div>
            <div class="form__element">
              <label class="label form__label" for="surname">Nachname*</label>
              <input class="input" id="surname" name="surname" type="text" data-form-input/>
            </div>
          </div>
          <div class="form__row">
            <div class="form__element">
              <label class="label form__label" for="company">Firmenname</label>
              <input class="input" id="company" name="company" type="text" data-form-input/>
            </div>
          </div>
          <div class="form__row">
            <div class="form__element">
              <label class="label form__label" for="adress">Straße und Hausnummer*</label>
              <input class="input" id="adress" name="adress" type="text" data-form-input/>
            </div>
          </div>
          <div class="form__row">
            <div class="form__element">
              <label class="label form__label" for="postcode">PLZ*</label>
              <input class="input" id="postcode" name="postcode" type="text" data-form-input/>
            </div>
            <div class="form__element">
              <label class="label form__label" for="city">Stadt*</label>
              <input class="input" id="city" name="city" type="text" data-form-input/>
            </div>
          </div>
          <div class="form__row">
            <div class="form__element">
              <label class="label form__label" for="bonus">Adresszusatz</label>
              <input class="input" id="bonus" name="bonus" type="text" data-form-input/>
            </div>
          </div>
          <button type="submit" class="button -blue form__button" @click="submitInvoiceChanges($event)">
            Änderungen übernehmen
          </button>
        </form>-->
        <form class="form account__form" ref="password" id="password" data-password-change-form>
          <h3 class="account__sub-headline">
            Passwort ändern
          </h3>
          <div class="form__row">
            <div class="form__element">
              <label class="label form__label" for="password_old">Altes Passwort*</label>
              <input class="input" id="password_old" name="password_old" type="password" data-form-input/>
            </div>
          </div>
          <div class="form__row">
            <div class="form__element">
              <label class="label form__label" for="password_new">Neues Passwort*</label>
              <input class="input" id="password_new" name="password_new" type="password" data-form-input/>
            </div>
          </div>
          <div class="form__row">
            <div class="form__element">
              <label class="label form__label" for="password_copy">Neues Passwort wiederholen*</label>
              <input class="input" id="password_copy" name="password_copy" type="password" data-form-input/>
            </div>
          </div>

          <div class="form__error" data-password-error>
            Da stimmt etwas nicht.
          </div>

          <div class="form__error" data-password-success>
            Das Passwort wurde geändert. <br>
            Bitte loggen Sie sich <router-link v-bind:to="{name: 'Login'}">hier</router-link> neu ein.
          </div>
          <button type="submit" class="button -blue form__button" @click="submitPasswordChange($event)">
            Passwort ändern
          </button>
        </form>
        <p>
          * erforderliche Eingaben
        </p>

<!--        <form class="form account__form" ref="invoices" id="invoices">
          <h3 class="account__sub-headline">
            Rechnungen
          </h3>
        </form>
        <form class="form account__form" ref="delete" id="delete">
          <h3 class="account__sub-headline">
            Account unwiederbringlich löschen
          </h3>
          <button type="submit" class="button -red form__button" @click="deleteAccount()">
            Account löschen
          </button>
        </form>-->
      </div>
    </div>
  </section>
  <teleport to="#header">
    <div class="account__nav">
      <button class="template-nav__item -back" v-on:click="$router.go(-1)" v-if="$route.name !== 'Organizer'">
        <Icon name="arrow-left" />
        <span>Zurück</span>
      </button>
    </div>
  </teleport>
</template>

<script>
import Icon from '@/components/generic/Icon';
import Sidebar from '@/components/layout/Sidebar';

export default {
  name: 'account',
  components: {Sidebar, Icon},
  data() {
    return {
      isMounted: false,
    };
  },
  props: {
    customerId: {
      type: String,
    },
  },
  computed: {
  },
  methods: {
    checkVisibility(container) {
      const sections = container.querySelectorAll('form')

      sections.forEach( section => {
        let id = section.getAttribute('id');
        let link = document.querySelector('#' + id + "-link")
        if (this.elementVisible(section, 100, 75)) {
          link.classList.add('-active')          ;
        } else {
          link.classList.remove('-active');
        }
      })
    },
    elementVisible(element, percentX, percentY){
      let tolerance = 0.01;
      if(percentX == null){
        percentX = 100;
      }
      if(percentY == null){
        percentY = 100;
      }

      const elementRect = element.getBoundingClientRect();
      const parentRects = [];

      while(element.parentElement != null){
        parentRects.push(element.parentElement.getBoundingClientRect());
        element = element.parentElement;
      }

      return parentRects.every(function (parentRect) {
        let visiblePixelX = Math.min(elementRect.right, parentRect.right) - Math.max(elementRect.left, parentRect.left);
        let visiblePixelY = Math.min(elementRect.bottom, parentRect.bottom) - Math.max(elementRect.top, parentRect.top);
        let visiblePercentageX = visiblePixelX / elementRect.width * 100;
        let visiblePercentageY = visiblePixelY / elementRect.height * 100;
        return visiblePercentageX + tolerance > percentX && visiblePercentageY + tolerance > percentY;
      });
    },
    scrollTo(refName) {
      const page = document.querySelector('main');
      const scrollable = this.$refs['viewport'];
      let target = this.$refs[refName];
      page.scrollTop = scrollable.offsetTop-page.offsetTop;
      scrollable.scrollTop = target.offsetTop-scrollable.offsetTop;
    },
    async submitPasswordChange(event) {
      event.preventDefault();
      const form = document.querySelector('[data-password-change-form]');
      const passwordError = form.querySelector('[data-password-error]');
      const passwordSuccess = form.querySelector('[data-password-success]');
      const value1 = form.querySelector('#password_new').value;
      const value2 = form.querySelector('#password_copy').value;
      const oldValue = form.querySelector('#password_old').value;

      passwordSuccess.classList.remove('-visible');

      if(this.validateForm(form)) {
        if (this.compareValues(value1, value2)) {
          const formData = new FormData();
          formData.append('password', value1);
          formData.append('password_old', oldValue);
          passwordError.classList.remove('-visible');

          const response = await fetch(`/api/v1/account/change-password`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(Object.fromEntries(formData))
          })

          switch (response.status) {
            case 200:
              passwordSuccess.classList.add('-visible');
              form.reset();
              await this.logout();
              break;
            default:
              console.log(`(${response.status}): ${response.error}`);
          }
        } else {
          passwordError.classList.add('-visible');
        }
      }
    },
    async logout() {
      const response = await fetch('/api/v1/account/logout', {
        method: 'POST'
      });
      if (response.status === 200) {
        this.$router.push({name: 'Login'});
      } else {
        console.error('Logout request failed')
      }
    },
    compareValues(value1, value2) {
      return value1 === value2;
    },
    validateForm(form) {
      // This function deals with validation of the form fields
      let valid = true;
      const inputs = form.querySelectorAll('[data-form-input]');
      // A loop that checks every input field in the current form:
      for (let i = 0; i < inputs.length; i++) {
        // If a field is empty...
        if (inputs[i].value === "") {
          // add an "invalid" class to the field:
          inputs[i].classList.add('-invalid');
          // and set the current valid status to false:
          valid = false;
        } else {
          inputs[i].classList.remove('-invalid');
        }
      }
      // If the valid status is true, mark the step as finished and valid:
      return valid;
    },
  },
  mounted() {
    const container = document.querySelector('#viewport');

    container.addEventListener('scroll', () => {
      this.checkVisibility(container);
    })
    this.checkVisibility(container);
  },
}
</script>

<style lang="scss" scoped>
.account {
  height: calc(100vh - 60px);
  display: flex;
  font-size: rem(15px);

  &__main {
    width: 100%;
    background-color: var(--bg-light-1);
    display: flex;
    flex-direction: column;
    position: relative;
    padding: rem(30px) rem(50px);
    overflow-y: scroll;
  }

  &__content {
    max-width: rem(560px);
  }

  &__headline {
    font-size: rem(32px);
    margin-bottom: rem(40px);
  }

  &__sub-headline {
    font-size: rem(24px);
    margin-bottom: rem(30px);
  }

  &__form {
    margin-bottom: rem(25px);
    padding-bottom: rem(25px);
    border-bottom: 2px solid var(--border-light-1);
  }

  &__links {
    width: 100%;
    list-style: none;
  }

  &__link {
    display: block;
    width: 100%;
    padding: rem(10px);
    margin-bottom: rem(2px);
    font-weight: 500;
    text-decoration: none;
    border-radius: rem(8px);
    cursor: pointer;

    &.-active {
      background-color: var(--border-light-2);
    }
  }

  &__nav {
    padding: 0 rem(50px);
    display: flex;
    align-items: center;
  }
}

</style>